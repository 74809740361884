import { Link, useHistory } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useInputChange } from "../../hooks/useInputChange";

export default function Searchbar(props) {

    const [searchInput, handleSearchInput] = useInputChange()
    let history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput !== "") history.push(`/search/${searchInput.searchInput}`);
    }

    return (
        <form className="flex" onSubmit={handleSubmit}>
            <input
                className="flex flex-1 px-4 py-2 rounded-l rounded-r-none shadow text-gray-700 appearance-none outline-none"
                type="text"
                placeholder="Produkt suchen..."
                name="searchInput"
                onChange={handleSearchInput}
            />
            <button
                className="flex items-center px-2 rounded-r shadow bg-gray-400 hover:bg-gray-300 hover:text-gray-800 focus:appearance-none focus:outline-none"
                type="submit"
            >
                <AiOutlineSearch size={22} />
            </button>
        </form>
    )
};

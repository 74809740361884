import axios from 'axios';
import settings from '../config';

axios.defaults.baseURL = settings.REACT_APP_API_URL + '/';

////////////////////////////////////////////
//
//  Settings
//

export async function getSettings() {
  return await axios
    .get('settings')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getSettingWelcomeMessage() {
  return await axios
    .get('settings/welcomeMessage')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postSettingWelcomeMessage(message) {
  return await axios
    .post('settings/welcomeMessage', { message })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Categories
//

// GET all categories
export async function getCategories() {
  return await axios
    .get('categories/all')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Products
//

// GET products by categoryId
export async function getProductsByCategoryId(category_id) {
  return await axios
    .get('products/all', {
      params: { category_id },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET highlight products
export async function getHighlightProducts(type, limit) {
  return await axios
    .get('products/highlightProducts', { params: { type, limit } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET products by search input
export async function getProductsSearchInput(searchInput, page, perPage) {
  return await axios
    .get('products/search/', {
      params: { searchInput, page, perPage },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Orders
//

// GET lieferservice delivery dates
export async function getDeliveryDates() {
  return await axios
    .get('deliveryDates/getDates')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Authentication
//

// POST Login user
export async function postLoginUser(loginData) {
  return await axios
    .post('auth/user/signin', loginData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Favorites
//

// GET products from favlist
export async function getProductsFromFavlist() {
  return await axios
    .get('favorites/getProducts')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET products by ids from favlist guest
export async function getProductsByIds(ids) {
  return await axios
    .get('favorites/getProductsByIds', { params: { ids } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Shopping Cart
//

// GET ShoppingCart Products
export async function getShoppingCartProducts(userId) {
  return await axios
    .get('shoppingCart/getProducts', {
      params: { userId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Subusers
//

// GET all subuser by userId
export async function getAllSubuserByUserId() {
  return await axios
    .get('subuser/getAllByUserId')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST create new subuser
export async function postCreateNewSubuser(data) {
  return await axios
    .post('subuser/addSubuser', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST edit subuser
export async function postEditSubuser(data) {
  return await axios
    .post('subuser/EditSubuser', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// DELETE delete subuser
export async function deleteSubuser(subuserId) {
  return await axios
    .delete('subuser/deleteSubuser', { data: { subuserId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Applay Account
//

export async function postApplayAccount(data) {
  return await axios
    .post('auth/applayAccount', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Reset Password without login
//

export async function postResetPasswordWithEmailRequest(data) {
  return await axios
    .post('auth/resetPasswordWithEmailRequest', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postResetPasswordWithEmail(data) {
  return await axios
    .post('auth/resetPasswordWithEmail', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  App initialization
//

// GET check if accesstoken is valid
export async function getCheckAccessToken(token) {
  return await axios
    .get('auth/user/checkaccesstoken', { params: { token } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET user shopping cart
export async function getShoppingCart(userId) {
  return await axios
    .get('shoppingCart', {
      params: { userId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET user favorites list
export async function getFavoritesList() {
  return await axios
    .get('favorites')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  ChangeHistories Interface
//

// GET history all
export async function getChangeHistory() {
  return await axios
    .get('changeHistory')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

////////////////////////////////////////////
//
//  Admin Interface
//

// GET user by userId
export async function getCustomersPagination(
  page,
  perPage,
  sortBy,
  sortDirection,
  nameInputFilter
) {
  return await axios
    .get('admin/customers', {
      params: { page, perPage, sortBy, sortDirection, nameInputFilter },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET user by userId
export async function getCustomerById(userId) {
  return await axios
    .get('admin/customer', {
      params: { userId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST customer activation response
export async function postCustomerActivationResponse(data) {
  return await axios
    .post('admin/customerActivationResponse', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin orders
export async function getAdminOrders(perPage, page, filter) {
  return await axios
    .get('admin/orders', {
      params: { perPage, page, filter },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin order
export async function getAdminOrder(orderId) {
  return await axios
    .get('admin/customer/order', {
      params: { orderId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin export orders
export async function getAdminExportSelectedOrders(orderIds) {
  return await axios
    .get('admin/exportSelectedOrders', {
      responseType: 'arraybuffer',
      params: { orderIds },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// GET all email templates
export async function getAllEmailTemplates() {
  return await axios
    .get('emailTemplates/getAllEmailTemplates')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST save email template
export async function saveEmail(data) {
  return await axios
    .post('emailTemplates/saveEmailtemplate', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST save product highlight
export async function postSaveProductHighlight(data) {
  return await axios
    .post('admin/highlightProduct', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin orders
export async function getAdminOrdersCalendar(month, type) {
  return await axios
    .get('admin/orders/calendar', {
      params: { month, type },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST edit category order
export async function postChangeCatrogryOrder(data) {
  return await axios
    .post('categories/editOrder/updown', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin statistics
export async function getAdminStatisticsOrdersCountByMonth() {
  return await axios
    .get('statistics/numberOrderCountByMonth')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin statistics
export async function getAdminStatisticsUserActivity() {
  return await axios
    .get('statistics/userActivity')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin products categories
export async function getAdminCategories() {
  return await axios
    .get('admin/categories')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// GET admin products
export async function getAdminProductsByCategory(categoryId) {
  return await axios
    .get('admin/productsByCategoryId', { params: { categoryId: categoryId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST admin change product available
export async function postAdminEditProduct(data) {
  return await axios
    .post('products/edit', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST admin change product available
export async function postAdminEditProductOrderUpDown(data) {
  return await axios
    .post('products/editOrder/updown', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

// POST admin change product available
export async function postAdminEditProductOrder(data) {
  return await axios
    .post('products/editOrder', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

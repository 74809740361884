import { useContext } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";

import { useHover } from "../../hooks/useHover";

import { AppContext } from "../../contexts/AppContext";

export default function Account(props) {

    const { user, signOutUser } = useContext(AppContext);

    const [ref, hovered] = useHover();

    return (
        <div ref={ref} className="flex relative">
            {user.isLoggedIn ? (
                <div className="mx-2 hover:text-gray-300" >
                    <AiOutlineUser size={28} />
                </div>
            ) : (
                    <NavLink to="/login" className="mx-2 hover:text-gray-300 font-semibold text-md" activeClassName="text-gray-300">Anmelden</NavLink>
                )
            }
            {user.isLoggedIn && hovered ? (
                <div className="absolute top-3 right-0 bg-white rounded-lg w-48 mt-1 md:mt-2 shadow-xl md:mr-4 z-50">
                    <Link to="/account" className="block px-4 py-2 text-gray-800 hover:bg-green-700 hover:text-white rounded-t-lg">Konto</Link>
                    <Link to="/account/orders" className="block px-4 py-2 text-gray-800 hover:bg-green-700 hover:text-white">Bestellungen</Link>
                    {user.user.createSubAccounts ? <Link to="/account/subusers" className="block px-4 py-2 text-gray-800 hover:bg-green-700 hover:text-white">Unterkonten</Link> : null}
                    {user.user.permissionLevel >= 8 ? <Link to="/admin/home" className="block px-4 py-2 text-gray-800 hover:bg-green-700 hover:text-white">Admin</Link> : null}
                    <a className="block px-4 py-2 text-gray-800 hover:bg-green-700 hover:text-white cursor-pointer border-t rounded-b-lg" onClick={() => signOutUser()}>Abmelden</a>
                </div>
            ) : null}
        </div>
    )
};

import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

export const AdminRoute = ({ component: Component, permissionLevelNeeded, ...rest }) => {

    const { user } = useContext(AppContext);

    if (user.isLoading === false && user.isLoggedIn === true && user.user !== null) {
        if (user.user.permissionLevel >= permissionLevelNeeded) {
            return (<Route {...rest} render={(props) => (
                <Component {...props} />
            )} />)
        } else {
            return <Redirect to="/" />
        }
    } else {
        return <Redirect to="/" />
    }
}
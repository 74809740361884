import React, { createContext, useReducer } from "react";

export const FavoritesContext = createContext();

const initalState = {
    isLoading: true,
    data: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case "addProduct":
            return { ...state, data: [...state.data, action.payload] };
        case "removeProduct":
            const index = state.data.findIndex(item => item === action.payload);
            state.data.splice(index, 1);
            return { ...state };
        case "init":
            return { isLoading: false, data: action.payload };
        case "reset":
            return { isLoading: true, data: [] }
    }
}

export const FavoritesProvider = ({ children }) => {
    const value = useReducer(reducer, initalState)
    return (
        <FavoritesContext.Provider value={value}>
            {children}
        </FavoritesContext.Provider>
    )
};
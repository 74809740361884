import { Link } from "react-router-dom";

export default function index() {

    return (
        <footer className="flex flex-wrap bg-gray-100 justify-center py-4 border-t border-gray-300 sm:space-x-4">
            <Link className="order-1 px-2 text-gray-800 text-base" to="/impressum">Impressum</Link>
            <Link className="order-2 px-2 text-gray-800 text-base" to="/datenschutz">Datenschutz</Link>
            <Link className="order-2 px-2 text-gray-800 text-base" to="/agb">AGB</Link>
        </footer>
    )
};

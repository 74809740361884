import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

export default function WelcomeMessage({ children }) {

    const { settings } = useContext(AppContext);

    const [alreadyRead, setAlreadyRead] = useState(false);

    useEffect(() => {
        const alreadyReadStorage = JSON.parse(sessionStorage.getItem("alreadyRead"));

        if (alreadyReadStorage === true) {
            setAlreadyRead(true);
        } else {
            setAlreadyRead(false);
        }
    });

    const submitMessage = () => {
        sessionStorage.setItem("alreadyRead", true);
        setAlreadyRead(true);
    }

    return (
        <>
            {alreadyRead !== true ? (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex justify-center text-center px-4 pt-20">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="bg-white px-4 pt-5 pb-4">
                                <h1 className="text-gray-800 font-bold text-xl">Willkommen</h1>
                                <p>{settings.data.welcomeMessage}</p>
                            </div>

                            <div className="flex bg-gray-50 px-4 py-3">
                                <button
                                    type="button"
                                    className="flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={() => submitMessage()}
                                >
                                    Zum Bestellsystem
                            </button>
                            </div>
                        </div>
                    </div>
                </div>) : null}

            {children}
        </>
    )
};

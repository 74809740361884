import React, { createContext, useReducer } from "react";

export const SettingsContext = createContext();

const initalState = {
    isLoading: true,
    data: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return { isLoading: false, data: action.payload };
        case "reset":
            return { isLoading: true, data: [] }
    }
}

export const SettingsProvider = ({ children }) => {
    const value = useReducer(reducer, initalState)
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    )
};
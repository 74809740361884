import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { AppProvider } from './contexts/AppContext';
import { UserProvider } from './contexts/UserContext';
import { CartProvider } from './contexts/CartContext';
import { FavoritesProvider } from './contexts/FavoritesContext';
import { SettingsProvider } from './contexts/SettingsContext';

import { ProtectedRoute } from './components/authentication/ProtectedRoute';
import { AdminRoute } from './components/authentication/AdminRoute';

import Navbar from './components/navbar';
import Footer from './components/footer';

import AppLoadingScreen from './components/appLoadingScreen';
import WelcomeMessage from './components/welcomeMessage';

const Home = React.lazy(() => import('./components/home'));
const Favorites = React.lazy(() => import('./components/favorites'));
const ProductsByCategory = React.lazy(() =>
  import('./components/productsByCategory')
);
const HighlightProducts = React.lazy(() =>
  import('./components/highlightProducts')
);
const SearchbarResult = React.lazy(() =>
  import('./components/searchbarResult')
);
const Cart = React.lazy(() => import('./components/cart'));
const Checkout = React.lazy(() => import('./components/checkout'));
const Impressum = React.lazy(() => import('./components/support/Impressum'));
const Datenschutz = React.lazy(() =>
  import('./components/support/Datenschutz')
);
const Agb = React.lazy(() => import('./components/support/AGB'));
const Login = React.lazy(() => import('./components/authentication/Login'));
const ApplyAccount = React.lazy(() => import('./components/applyAccount'));
const ResetPassword = React.lazy(() => import('./components/resetPassword'));
const NewPasswort = React.lazy(() =>
  import('./components/resetPassword/NewPasswort')
);
const Account = React.lazy(() => import('./components/account'));
const Subusers = React.lazy(() => import('./components/subusers'));
const Orders = React.lazy(() => import('./components/orders'));
const Order = React.lazy(() => import('./components/order'));

const AdminSidebar = React.lazy(() => import('./components/admin/sidebar'));
const AdminHome = React.lazy(() => import('./components/admin/home'));
const AdminCharts = React.lazy(() => import('./components/admin/charts'));
const AdminCustomers = React.lazy(() => import('./components/admin/customers'));
const AdminCustomer = React.lazy(() => import('./components/admin/customer'));
const AdminCustomerOrder = React.lazy(() =>
  import('./components/admin/customerOrder')
);
const AdminCategories = React.lazy(() =>
  import('./components/admin/categories')
);
const AdminProducts = React.lazy(() => import('./components/admin/products'));
const AdminAbholung = React.lazy(() => import('./components/admin/abholung'));
const AdminLieferservice = React.lazy(() =>
  import('./components/admin/lieferservice')
);
const AdminOrders = React.lazy(() => import('./components/admin/orders'));
const AdminOrder = React.lazy(() => import('./components/admin/order'));
const AdminHistoryLog = React.lazy(() =>
  import('./components/admin/historyLog')
);
const AdminSettings = React.lazy(() => import('./components/admin/settings'));
const AdminSettingsWelcomeMessage = React.lazy(() =>
  import('./components/admin/settings/welcomeMessage')
);
const AdminSettingsEmails = React.lazy(() =>
  import('./components/admin/settings/emails')
);
const AdminLieferserviceDates = React.lazy(() =>
  import('./components/admin/settings/lieferserviceDates')
);

function App() {
  return (
    <Router>
      <SettingsProvider>
        <UserProvider>
          <CartProvider>
            <FavoritesProvider>
              <AppProvider>
                <div className="flex flex-1 flex-col bg-gray-200 min-h-screen">
                  <AppLoadingScreen>
                    <WelcomeMessage>
                      <Navbar />
                      <div className="flex flex-1 flex-col justify-between">
                        <React.Suspense fallback={<AppLoadingScreen />}>
                          <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                              exact
                              path="/productsByCategory/:category_id?"
                              component={ProductsByCategory}
                            />
                            <Route
                              exact
                              path="/highlightProducts"
                              component={HighlightProducts}
                            />
                            <Route
                              exact
                              path="/search/:searchInput?"
                              component={SearchbarResult}
                            />
                            <Route
                              exact
                              path="/favorites"
                              component={Favorites}
                            />
                            <Route exact path="/cart" component={Cart} />
                            <Route
                              exact
                              path="/impressum"
                              component={Impressum}
                            />
                            <Route
                              exact
                              path="/datenschutz"
                              component={Datenschutz}
                            />
                            <Route exact path="/agb" component={Agb} />
                            <Route exact path="/login" component={Login} />
                            <Route
                              exact
                              path="/resetPassword"
                              component={ResetPassword}
                            />
                            <Route
                              exact
                              path="/resetPassword/:resetToken"
                              component={NewPasswort}
                            />
                            <Route
                              exact
                              path="/applyAccount"
                              component={ApplyAccount}
                            />
                            <Route
                              exact
                              path="/signup"
                              component={ApplyAccount}
                            />
                            <Route
                              exact
                              path="/benutzerkontobeantragen"
                              component={ApplyAccount}
                            />
                            <Route
                              exact
                              path="/kontoBeantragen"
                              component={ApplyAccount}
                            />
                            <ProtectedRoute
                              exact
                              path="/account"
                              component={Account}
                            />
                            <ProtectedRoute
                              exact
                              path="/account/subusers"
                              component={Subusers}
                            />
                            <ProtectedRoute
                              exact
                              path="/account/orders"
                              component={Orders}
                            />
                            <ProtectedRoute
                              exact
                              path="/account/order/:orderId?"
                              component={Order}
                            />
                            <ProtectedRoute
                              exact
                              path="/checkout"
                              component={Checkout}
                            />
                            <AdminRoute
                              permissionLevelNeeded={8}
                              path="/admin"
                              component={AdminSwitch}
                            />
                          </Switch>
                        </React.Suspense>
                        <Footer />
                      </div>
                    </WelcomeMessage>
                  </AppLoadingScreen>
                </div>
              </AppProvider>
            </FavoritesProvider>
          </CartProvider>
        </UserProvider>
      </SettingsProvider>
    </Router>
  );
}

function AdminSwitch() {
  return (
    <div className="flex flex-row flex-1">
      <AdminSidebar />
      <React.Suspense fallback={<AppLoadingScreen />}>
        <Switch>
          <Route exact path={'/admin/home'} component={AdminHome} />
          <Route exact path={'/admin/charts'} component={AdminCharts} />
          <Route exact path={'/admin/customers'} component={AdminCustomers} />
          <Route
            exact
            path={'/admin/customer/order/:orderId?'}
            component={AdminCustomerOrder}
          />
          <Route
            exact
            path={'/admin/customer/:userId?'}
            component={AdminCustomer}
          />
          <Route exact path={'/admin/categories'} component={AdminCategories} />
          <Route exact path={'/admin/products'} component={AdminProducts} />
          <Route exact path={'/admin/orders'} component={AdminOrders} />
          <Route exact path={'/admin/order/:orderId?'} component={AdminOrder} />
          <Route exact path={'/admin/abholung'} component={AdminAbholung} />
          <Route
            exact
            path={'/admin/lieferservice'}
            component={AdminLieferservice}
          />
          <Route exact path={'/admin/historyLog'} component={AdminHistoryLog} />
          <Route exact path={'/admin/settings'} component={AdminSettings} />
          <Route
            exact
            path={'/admin/settings/welcomeMessage'}
            component={AdminSettingsWelcomeMessage}
          />
          <Route
            exact
            path={'/admin/settings/lieferservice'}
            component={AdminLieferserviceDates}
          />
          <Route
            exact
            path={'/admin/settings/emails'}
            component={AdminSettingsEmails}
          />
        </Switch>
      </React.Suspense>
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";

// components
import Favorites from "./Favorites";
import Cart from "./Cart";
import Account from "./Account";
import Searchbar from "./Searchbar";

export default function Navbar() {

    return (
        <nav className="flex flex-wrap items-center justify-between bg-green-600 p-2 lg:py-4 md:px-4 lg:px-10 xl:px-48 text-white box-border">
            <div className="flex order-0">
                <Link to="/" className="text-xl font-medium">Hofladen-Massing</Link>
            </div>
            <div className="flex order-2 mt-2 md:mt-0 md:order-1 w-full md:w-2/5">
                <div className="flex flex-1 flex-col relative">
                    <Searchbar />
                </div>

            </div>
            <div className="flex order-1 md:order-2">
                <div className="flex items-center">
                    <Favorites />
                    <Cart />
                    <Account />
                </div>
            </div>

        </nav>
    )
}
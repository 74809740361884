import React, { useEffect, useContext, useState } from "react";
import { AiOutlineSetting } from "react-icons/ai"

import { AppContext } from "../../contexts/AppContext";

export default function AppLoadingScreen({ children }) {

    const [showDebugView, setShowDebugView] = useState(false);

    const { user, cart, favorites, settings } = useContext(AppContext);

    return (
        <div className="flex flex-1 flex-col">
            {settings.isLoading || user.isLoading || cart.isLoading || favorites.isLoading ? (
                <div className="flex justify-center mt-5">
                    <p className="text-xl font-bold text-gray-800">Seite wird geladen...</p>
                </div>
            ) :
                settings.data.maintenance === false ? children : (
                    <div className="flex justify-center mt-5">
                        <p className="text-xl font-bold text-gray-800">Wartungsarbeiten</p>
                    </div>
                )
            }
        </div>
    )
};

/*

<AiOutlineSetting className="absolute top-5 left-5 text-white cursor-pointer" size={22} onClick={() => setShowDebugView(!showDebugView)} />
            {showDebugView ? (
                <div className="flex flex-col">
                    <div className="flex flex-1 flex-row justify-between">
                        <div className="flex flex-1 flex-col">
                            <p>USER:</p>
                            <p>isLoading: {String(user.isLoading)}</p>
                            <p>isLoggedIn: {String(user.isLoggedIn)}</p>
                            <p>isGuest: {String(user.isGuest)}</p>
                            <p>user: {user.user !== null ? user.user._id : ("")}</p>
                            <p>guestId: {user.guestId}</p>
                        </div>
                        <div className="flex flex-1 flex-col">
                            <p>CART:</p>
                            <p>isLoading: {String(cart.isLoading)}</p>
                            <p>Data: {cart.data.map((item) => (<p>{item.productId} - {item.quantity} - {item.unit}</p>))}</p>
                        </div>
                        <div className="flex flex-1 flex-col">
                            <p>FAV:</p>
                            <p>isLoading: {String(favorites.isLoading)}</p>
                            <p>Data: {favorites.data.map((item) => (<p>{item}</p>))}</p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center py-2">
                        {resetApplication ? (
                            <button
                                onClick={() => setResetApplication(false)}
                                className="bg-green-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                            >Resume Appworkflow
                            </button>
                        ) : (
                                <button
                                    onClick={() => setResetApplication(true)}
                                    className="bg-red-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                                >Stop Appworkflow
                                </button>
                            )}
                        <button
                            onClick={() => dispatchUser({ type: "reset" })}
                            className="bg-red-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                        >Reset user
                    </button>
                        <button
                            onClick={() => dispatchCart({ type: "reset" })}
                            className="bg-red-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                        >Reset cart
                    </button>
                        <button
                            onClick={() => dispatchFavorites({ type: "reset" })}
                            className="bg-red-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                        >Reset favlist
                    </button>
                        <button
                            onClick={() => resetApp()}
                            className="bg-red-500 text-white font-semibold mx-4 mx-2 px-4 rounded hover:bg-red-800"
                        >Reset App
                    </button>
                    </div>
                </div>
            ) : null}

            */
import React, { createContext, useReducer } from 'react';

export const UserContext = createContext([{}, () => { }]);

const initialState = {
    isLoading: true,
    isLoggedIn: false,
    isGuest: true,
    user: null,
    token: null,
    guestId: null
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
            return { ...state, isLoading: true };
        case "SET_AUTHORIZED":
            return { isLoading: false, isLoggedIn: true, isGuest: false, user: action.payload.user, token: action.payload.token };
        case "SET_UNAUTHORIZED":
            return { isLoading: false, isLoggedIn: false, isGuest: true, user: null, guestId: action.payload };
        case "SET_TOKEN":
            return { ...state, token: action.payload }
        case "reset":
            return { isLoading: true, isLoggedIn: false, isGuest: true, user: null, token: null, guestId: null }
    }
}

export const UserProvider = ({ children }) => {
    const value = useReducer(reducer, initialState);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

import { useContext } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

export default function Cart(props) {

    const { cart } = useContext(AppContext);

    return (
        <NavLink
            className="flex mx-2 hover:text-gray-300"
            activeClassName="text-gray-300"
            to="/cart"
        >
            <AiOutlineShoppingCart size={28} />{cart.data.length}
        </NavLink>
    )
};

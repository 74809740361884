import { useState } from "react"

export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (err) {
            console.warn("Setting localStorage went wrong: ", err);
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            setStoredValue(value);
        } catch (err) {
            console.error(err);
        }

    };

    const deleteKey = (key) => {
        try {
            localStorage.removeItem(key);
            setStoredValue(null);
        } catch (err) {
            console.log(err);
        }
    }

    return [storedValue, setValue, deleteKey];
}

export default useLocalStorage;
import { AiOutlineHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";

export default function Favorites(props) {
    return (
        <NavLink
            className="mx-2 hover:text-gray-300"
            activeClassName="text-gray-300"
            to="/favorites"
        >
            <AiOutlineHeart size={28} />
        </NavLink>
    )
};

import { useRef, useState, useEffect } from "react";

export function useHover() {
    const ref = useRef(null);
    const [hovered, setHovered] = useState(false);

    const enter = () => setHovered(true);
    const leave = () => setHovered(false);

    useEffect(() => {
        const node = ref.current;
        node.addEventListener("mouseenter", enter);
        node.addEventListener("mouseleave", leave);
        return () => {
            node.removeEventListener("mouseenter", enter);
            node.removeEventListener("mouseleave", leave);
        }
    }, [ref.current]);

    return [ref, hovered];
}
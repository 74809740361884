import React, { useState, useEffect, createContext, useContext } from "react";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import customId from "crypto-random-string";
import axios from "axios";
import moment from "moment-timezone";

import { getSettings, getCheckAccessToken, getShoppingCart, getFavoritesList } from "../helpers/apicalls";

// contexts
import { UserContext } from "./UserContext";
import { FavoritesContext } from "./FavoritesContext";
import { CartContext } from "./CartContext";
import { SettingsContext } from "./SettingsContext";

// hooks
import useLocalStorage from "../hooks/useLocalStorage";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  moment().tz("Europe/Berlin").format();
  const [user, dispatchUser] = useContext(UserContext);
  const [favorites, dispatchFavorites] = useContext(FavoritesContext);
  const [cart, dispatchCart] = useContext(CartContext);
  const [settings, dispatchSettings] = useContext(SettingsContext);

  const [
    jwtAccessToken,
    setJwtAccessToken,
    deleteJwtAccessToken,
  ] = useLocalStorage("accessToken");
  const [
    jwtRefreshToken,
    setJwtRefreshToken,
    deleteRefreshToken,
  ] = useLocalStorage("refreshToken");
  const [guestId, setGuestId] = useLocalStorage("guestId");
  const [guestFavoriteList, setGuestFavoriteList] = useLocalStorage(
    "guestFavoriteList"
  );

  const [resetApplication, setResetApplication] = useState(false);
  const [signOutUserState, setSignOutUserState] = useState(false);

  // interceptors reqest
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (JSON.parse(localStorage.getItem("accessToken")) !== null) {
          config.headers.authorization = JSON.parse(
            localStorage.getItem("accessToken")
          );
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, []);

  // interceptors response
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 403 && originalRequest._retry) {
          signOutUser();
          return Promise.reject(error);
        }

        if (error.response.status === 403 && !originalRequest._retry) {
          const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
          originalRequest._retry = true;
          return axios
            .post(
              process.env.REACT_APP_API_URL + "/auth/user/updateaccesstoken",
              {
                refreshToken: refreshToken,
              },
              {
                _retry: true,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const newAccessToken = response.data.accessToken;
                setJwtAccessToken(newAccessToken);
                decodeJWTandSetUser(newAccessToken);
                axios.defaults.headers.authorization = newAccessToken;
                originalRequest.headers.authorization = newAccessToken;
                return axios(originalRequest);
              }
            })
            .catch((error) => {
              console.log(error);
              return signOutUser();
            });
        }

        return Promise.reject(error);
      }
    );
  }, []);

  let history = useHistory();

  const userId = () => {
    if (
      user.isLoading === false &&
      user.isLoggedIn === true &&
      user.user !== null &&
      user.isGuest === false
    ) {
      return user.user._id;
    } else {
      return user.guestId;
    }
  };

  const signInUser = (accessToken, refreshToken) => {
    setJwtAccessToken(accessToken);
    setJwtRefreshToken(refreshToken);
    resetApp();
  };

  const signOutUser = async () => {
    setResetApplication(true);
    setSignOutUserState(true);
    deleteJwtAccessToken("accessToken");
    deleteRefreshToken("refreshToken");
    resetApp();
  };

  const loadShoppingCart = (userId) => {
    getShoppingCart(userId)
      .then((res) => {
        dispatchCart({ type: "init", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadFavorites = () => {
    getFavoritesList()
      .then((res) => {
        dispatchFavorites({ type: "init", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetApp = () => {
    setResetApplication(true);
    dispatchSettings({ type: "reset" });
    dispatchUser({ type: "reset" });
    dispatchCart({ type: "reset" });
    dispatchFavorites({ type: "reset" });
  };

  const decodeJWTandSetUser = async (accessToken = jwtAccessToken) => {
    const jwtContent = await jwt.decode(accessToken);
    dispatchUser({
      type: "SET_AUTHORIZED",
      payload: { user: jwtContent["user"], token: accessToken },
    });
  };

  const checkIfAccessTokenIsValid = () => {
    getCheckAccessToken(jwtAccessToken)
      .then((res) => {
        decodeJWTandSetUser(jwtAccessToken);
      })
      .catch((err) => {
        signOutUser();
        console.log(err);
      });
  };

  const authheader = () => {
    if (user.isLoading === false && user.isLoggedIn) {
      return { authorization: jwtAccessToken };
    } else {
      return {};
    }
  };

  // Application loaded
  useEffect(() => {
    if (resetApplication === false) {
      (async function anon() {
        // loading Settings
        if (settings.isLoading === true) {
          getSettings()
            .then((response) => {
              dispatchSettings({ type: "init", payload: response });
            })
            .catch((error) => {
              console.log(error);
            });
        }

        // prüfen ob userContext lädt
        else if (user.isLoading === true) {
          // console.log('====================================');
          // console.log("Load: user");
          // console.log('====================================');

          // wenn ja schauen ob ein access und refresh token im localstorage vorhanden ist
          if (jwtAccessToken === null || jwtRefreshToken === null) {
            // keine tokens hinterlägt daher als user als guest im usercontext anmelden
            // prüfen ob eine guestId im localstorage vorhanden ist
            if (guestId === null) {
              // keine guestID hinterlegt also erstellen
              var newGuestId = await `guest-${customId({ length: 12 })}`;
              await setGuestId(newGuestId);
            } else {
              // guest mit id beim userContext anmelden
              dispatchUser({ type: "SET_UNAUTHORIZED", payload: guestId });
            }
          } else {
            // tokens vorhanden -> accesstoken an server senden und prüfen lassen
            decodeJWTandSetUser(jwtAccessToken);
          }
        }
        // warenkorb laden sobald user als gast oder nutzer angemeldet ist
        else if (user.isLoading === false && cart.isLoading === true) {
          if (user.user !== null || user.guestId !== null) {
            // console.log('====================================');
            // console.log("Load: cart");
            // console.log('====================================');

            if (user.isGuest === true) {
              // warenkorb mit der guastId laden
              loadShoppingCart(user.guestId);
            } else {
              loadShoppingCart(user.user._id);
            }
          }
        }
        // favoritenliste laden sobald der warenkorb geladen wurde
        else if (
          user.isLoading === false &&
          cart.isLoading === false &&
          favorites.isLoading === true
        ) {
          if (user.user !== null || user.guestId !== null) {
            // console.log('====================================');
            // console.log("Load: favlist");
            // console.log('====================================');

            if (user.isGuest === true) {
              // favoritenliste vom localstorage laden
              var favListfromStorage = (await guestFavoriteList) || [];
              dispatchFavorites({ type: "init", payload: favListfromStorage });
            } else if (user.isLoggedIn === true && jwtAccessToken !== null) {
              // favoritenliste vom server laden
              loadFavorites();
            }
          }
        }
      })();
    }
  }, [resetApplication, settings, user, guestId, cart, favorites]);

  useEffect(() => {
    if (resetApplication) {
      //if (settings.isLoading === true && user.isLoading === true && cart.isLoading === true && favorites.isLoading === true) {
      if (signOutUserState === true) {
        if (jwtAccessToken === null && jwtRefreshToken === null) {
          setSignOutUserState(false);
          setResetApplication(false);
          history.push("/");
        }
      } else {
        setResetApplication(false);
        history.push("/");
      }
      // }
    }
  }, [
    resetApplication,
    user,
    cart,
    favorites,
    jwtAccessToken,
    jwtRefreshToken,
  ]);

  // save favoritelsit to localstore wenn update
  useEffect(() => {
    if (
      user.isLoading === false &&
      favorites.isLoading === false &&
      user.isGuest === true
    ) {
      setGuestFavoriteList(favorites.data);
    }
  }, [favorites]);

  return (
    <AppContext.Provider
      value={{
        resetApplication,
        setResetApplication: (value) => setResetApplication(value),
        settings,
        dispatchSettings,
        loadShoppingCart,
        resetApp,
        jwtAccessToken,
        setJwtAccessToken,
        jwtRefreshToken,
        setJwtRefreshToken,
        user,
        authheader,
        userId,
        signInUser,
        signOutUser,
        dispatchUser,
        favorites,
        dispatchFavorites,
        cart,
        dispatchCart,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};  
import React, { createContext, useReducer } from "react";

export const CartContext = createContext();

const initalState = {
    isLoading: true,
    data: []
}

const reducer = (state, action) => {
    switch (action.type) {
        case "addProduct":
            return { ...state, data: [...state.data, action.payload] };
        case "updateProduct":
            state.data.splice(state.data.findIndex((item) => item.productId === action.payload.productId), 1, action.payload);
            return { ...state };
        case "removeProduct":
            state.data.splice(state.data.findIndex((item) => item.productId === action.payload), 1);
            return { ...state };
        case "init":
            return { isLoading: false, data: action.payload };
        case "reset":
            return { isLoading: true, data: [] }
        default: return state
    }
}

export const CartProvider = ({ children }) => {
    const value = useReducer(reducer, initalState)
    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
};
